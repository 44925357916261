<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="修改优惠方式"
    :visible.sync="show"
    width="550px"
  >
    <el-form size="small" :model="form" ref="form" label-width="100px">
      <el-form-item label="名称">
        <span>{{ form.name || '-' }}</span>
      </el-form-item>

      <el-form-item label="优惠方式" prop="discountWay">
        <el-select v-model="form.discountWay" clearable>
          <el-option
            v-for="item in wayList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <p class="tips">注意：修改优惠方式后，将立即生效新的优惠规则</p>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      wayList: [
        {
          label: '返佣',
          value: 1
        },
        {
          label: '商品改价',
          value: 2
        },
        {
          label: '无优惠',
          value: 0
        }
      ],
      form: {
        name: '',
        discountWay: 1
      }
    }
  },
  async created() {
    //门店仅支持设置商品改价、无优惠
    if (this.currentItem.customerType == 1) {
      this.wayList = this.wayList.slice(1)
    }

    this.form.discountWay = +this.currentItem.discountWay || 0

    this.form.name = this.currentItem.name
  },

  methods: {
    async formSubmit() {
      this.loading = true

      const sendData = { ...this.form }

      sendData.id = this.currentItem.id

      try {
        await this.$api.operatorsConfig.waySave(sendData)

        this.$message.success('修改成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tips {
  color: brown;
  margin-left: 33px;
}
</style>
