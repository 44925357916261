<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="ID" prop="middlemanId">
          <el-input v-model="params.middlemanId" />
        </el-form-item>

        <el-form-item label="名称" prop="name">
          <el-input v-model="params.name" />
        </el-form-item>

        <el-form-item label="用户类型" prop="customerType">
          <el-select v-model="params.customerType" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button v-auth="'discount:addStore'" type="primary" @click="addStore"
        >添加门店</el-button
      >
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="middlemanId"
          label="ID"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="名称"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="type" label="用户类型">
          <template slot-scope="{ row }">
            <span>{{ row.customerType == 1 ? '门店' : '中间商' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkMan"
          label="联系人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkPhone"
          label="联系电话"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="优惠方式">
          <template slot-scope="{ row }">
            <span>{{
              row.discountWay == 1
                ? '返佣'
                : row.discountWay == 2
                ? '商品改价'
                : '无优惠'
            }}</span>
          </template></el-table-column
        >
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="修改时间"
        >
          <template slot-scope="{ row }">
            <span>{{ row.createTime | date }}</span>
          </template>
        </el-table-column>
        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'discount:updateDiscount'"
              @click="editWay(row)"
              type="text"
              >修改优惠方式</el-button
            >
            <el-button
              v-auth="
                `${
                  row.discountWay == 1
                    ? 'discount:updateReturnCommission'
                    : 'supplierGoods:discountGoods'
                }`
              "
              v-if="row.discountWay"
              @click="editAmount(row)"
              type="text"
              >设置{{
                row.discountWay == 1 ? '返佣额度' : '商品价格'
              }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :currentItem="currentItem"
    />
    <WayModal
      v-if="wayVisible"
      @getData="getData(true)"
      :visible.sync="wayVisible"
      :currentItem="currentItem"
    />
    <AmountModal
      @getData="getData(true)"
      v-if="amountVisible"
      :visible.sync="amountVisible"
      :currentItem="currentItem"
    />
    <AmountModal2
      v-if="amountVisible2"
      :visible.sync="amountVisible2"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
import WayModal from './components/WayModal'
import AmountModal from './components/AmountModal'
import AmountModal2 from './components/AmountModal2'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: null,
      addVisible: false,
      wayVisible: false,
      amountVisible: false,
      amountVisible2: false,
      tableData: [],
      typeList: [
        {
          label: '中间商',
          value: 0
        },
        {
          label: '门店',
          value: 1
        }
      ],
      params: {
        name: '',
        customerType: '',
        middlemanId: '',
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    AddModal,
    WayModal,
    AmountModal,
    AmountModal2
  },
  created() {
    this.getData()
  },
  methods: {
    addStore() {
      this.currentItem = null
      this.addVisible = true
    },
    editWay(row) {
      this.currentItem = row
      this.wayVisible = true
    },
    editAmount(row) {
      this.currentItem = row
      if (row.discountWay == 1) {
        this.amountVisible = true
      } else {
        this.amountVisible2 = true
      }
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.operatorsConfig.getList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            item.discountWay = Number(item.discountWay)
            return item
          })
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
