var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('template',{slot:"headerLeft"},[_c('el-form',{ref:"filtersForm",attrs:{"inline":"","model":_vm.params}},[_c('el-form-item',{attrs:{"label":"ID","prop":"middlemanId"}},[_c('el-input',{model:{value:(_vm.params.middlemanId),callback:function ($$v) {_vm.$set(_vm.params, "middlemanId", $$v)},expression:"params.middlemanId"}})],1),_c('el-form-item',{attrs:{"label":"名称","prop":"name"}},[_c('el-input',{model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1),_c('el-form-item',{attrs:{"label":"用户类型","prop":"customerType"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.params.customerType),callback:function ($$v) {_vm.$set(_vm.params, "customerType", $$v)},expression:"params.customerType"}},_vm._l((_vm.typeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1),_c('template',{slot:"headerRight"},[_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:('discount:addStore'),expression:"'discount:addStore'"}],attrs:{"type":"primary"},on:{"click":_vm.addStore}},[_vm._v("添加门店")])],1),_c('div',{staticClass:"table-wrap"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","height":"100%"}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"middlemanId","label":"ID"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"name","label":"名称"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"type","label":"用户类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.customerType == 1 ? '门店' : '中间商'))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"linkMan","label":"联系人"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"linkPhone","label":"联系电话"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"优惠方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.discountWay == 1 ? '返佣' : row.discountWay == 2 ? '商品改价' : '无优惠'))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"createTime","label":"修改时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(row.createTime)))])]}}])}),_c('el-table-column',{attrs:{"width":"220","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:('discount:updateDiscount'),expression:"'discount:updateDiscount'"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.editWay(row)}}},[_vm._v("修改优惠方式")]),(row.discountWay)?_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:(
              ("" + (row.discountWay == 1
                  ? 'discount:updateReturnCommission'
                  : 'supplierGoods:discountGoods'))
            ),expression:"\n              `${\n                row.discountWay == 1\n                  ? 'discount:updateReturnCommission'\n                  : 'supplierGoods:discountGoods'\n              }`\n            "}],attrs:{"type":"text"},on:{"click":function($event){return _vm.editAmount(row)}}},[_vm._v("设置"+_vm._s(row.discountWay == 1 ? '返佣额度' : '商品价格'))]):_vm._e()]}}])})],1)],1),_c('pagination',{attrs:{"slot":"pagination","totalCount":_vm.totalCount,"page-size":_vm.params.limit,"page":_vm.params.page},on:{"update:pageSize":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page-size":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"change":_vm.getData},slot:"pagination"}),(_vm.addVisible)?_c('AddModal',{attrs:{"visible":_vm.addVisible,"currentItem":_vm.currentItem},on:{"getData":function($event){return _vm.getData(true)},"update:visible":function($event){_vm.addVisible=$event}}}):_vm._e(),(_vm.wayVisible)?_c('WayModal',{attrs:{"visible":_vm.wayVisible,"currentItem":_vm.currentItem},on:{"getData":function($event){return _vm.getData(true)},"update:visible":function($event){_vm.wayVisible=$event}}}):_vm._e(),(_vm.amountVisible)?_c('AmountModal',{attrs:{"visible":_vm.amountVisible,"currentItem":_vm.currentItem},on:{"getData":function($event){return _vm.getData(true)},"update:visible":function($event){_vm.amountVisible=$event}}}):_vm._e(),(_vm.amountVisible2)?_c('AmountModal2',{attrs:{"visible":_vm.amountVisible2,"currentItem":_vm.currentItem},on:{"update:visible":function($event){_vm.amountVisible2=$event}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }