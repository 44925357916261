<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="添加门店"
    :visible.sync="show"
    width="550px"
  >
    <el-form
      size="small"
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
    >
      <el-form-item label="来源" prop="resource">
        <el-select clearable v-model="form.resource">
          <el-option
            v-for="item in resourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店编号" prop="middlemanId">
        <div style="display: flex">
          <el-input
            style="width: 215px; margin-right: 10px"
            v-model="form.middlemanId"
            placeholder="请输入门店编号"
          ></el-input>
          <el-button type="plain" size="mini" @click="checkHandler"
            >查询</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="门店名称" prop="name">
        <span v-if="!form.name" type="text">点击查询获取</span>
        <span v-else>{{ form.name }}</span>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    const rules = {
      resource: [
        {
          required: true,
          message: '请选择来源'
        }
      ],
      middlemanId: [
        {
          required: true,
          message: '请输入门店编号'
        }
      ],
      name: [
        {
          required: true,
          message: '请获取门店名称'
        }
      ]
    }

    return {
      rules,
      loading: false,
      resourceList: [
        {
          label: '工农e家',
          value: 1
        }
      ],
      form: {
        customerType: 1,
        resource: 1,
        middlemanId: '',
        name: ''
      }
    }
  },
  async created() {
    // this.getSourceList()
  },

  methods: {
    async getSourceList() {
      try {
        const res = this.$api.commom.getResourceList()
        this.resourceList = res.map(item => ({
          label: item.name,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },

    checkHandler() {
      if (!this.form.middlemanId) {
        this.$message.warning('请先输入门店编号')
        return
      }

      this.getStoreName(this.form.middlemanId)

      this.$refs.form.clearValidate()
    },
    async getStoreName(id) {
      try {
        const res = await this.$api.operatorsConfig.getStoreName({
          storeId: id
        })

        this.form.name = res.store.storeName || '-'
      } catch (e) {
        console.log(e)
      }
    },
    formSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          try {
            await this.$api.operatorsConfig.operatorsConfigSave(this.form)
            this.$message.success('添加成功')
            this.show = false

            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
