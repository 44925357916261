<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="设置返佣额度"
    :visible.sync="show"
    width="550px"
  >
    <el-form size="small" :model="form" ref="form" label-width="100px">
      <el-form-item label="名称">
        <span>{{ form.name || '-' }}</span>
      </el-form-item>

      <el-form-item label="返佣额度" prop="returnCommission">
        <!-- <el-input type="number" v-model="form.returnCommission"></el-input> -->
        <el-input
          maxlength="3"
          @blur="blurHandler"
          @input="val => (form.returnCommission = val.replace(/[^0-9.]/g, ''))"
          style="width: 60px"
          v-model="form.returnCommission"
        ></el-input
        >&nbsp;&nbsp;%
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      form: {
        name: '',
        returnCommission: 0
      }
    }
  },
  async created() {
    this.form.name = this.currentItem.name
    this.form.returnCommission = this.currentItem.returnCommission || 0
  },

  methods: {
    blurHandler() {
      if (+this.form.returnCommission > 100) {
        this.form.returnCommission = 100
      }
    },
    async formSubmit() {
      this.loading = true

      const sendData = { ...this.form }

      sendData.id = this.currentItem.id

      try {
        await this.$api.operatorsConfig.amountSave(sendData)

        this.$message.success('设置成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
